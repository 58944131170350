import React from "react"

export const TitleUnderlineCovertionsOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="202" height="11" viewBox="0 0 202 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.6554 9.22253C32.8157 8.45567 3.06003 10.3998 0.937367 10.2472C-0.945708 9.98431 0.391155 4.11282 1.85678 3.19494C2.37642 2.8687 3.05384 2.78353 4.67251 2.87725C6.09216 2.95859 15.8767 2.52189 39.5475 1.55649C40.011 1.72686 41.1829 1.09194 41.4603 1.30583C41.6719 1.42998 45.1097 1.38973 50.2202 1.29278C55.9546 1.18291 71.5654 0.798845 79.2644 0.650826C90.627 0.424861 86.3531 0.459963 99.3612 0.277635C102.082 0.238555 99.9565 0.605159 103.156 0.45735C109.98 0.147597 124.896 0.451643 130.061 0.170758C134.204 -0.0550014 135.106 -0.0662006 139.086 0.176079C142.271 0.368785 164.741 0.429 167.278 0.0872628C167.889 -0.0150652 168.353 0.0380418 168.339 0.166382C168.604 0.179184 183.56 0.6119 183.626 0.718396C183.731 0.847472 184.182 0.922833 184.62 0.841776C185.417 0.701508 201.676 0.684381 201.9 1.06545C202.53 2.16376 199.755 6.64719 197.797 7.7128C195.747 8.82809 185.669 7.21933 177.012 7.74114C177.012 7.74114 174.571 7.69819 170.908 7.62538C135.143 6.95842 136.362 7.27303 129.538 7.58837C128.571 7.19155 124.916 8.12945 122.65 7.45103C121.709 7.17163 115.06 7.13068 113.572 7.4007C113.253 7.46016 110.891 7.46795 107.453 7.46353C104.029 7.4592 99.5295 7.43707 94.9769 7.46487C92.6939 7.47873 90.3977 7.48693 88.2209 7.51261C86.0308 7.53262 83.9467 7.58121 82.1015 7.63127C78.3979 7.7313 75.6364 7.86505 74.7857 8.072C68.0336 7.48321 44.108 8.8714 37.6554 9.22253Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineCovertionsTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="292" height="11" viewBox="0 0 292 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.1079 9.39533C48.1472 8.61541 5.36931 10.4794 2.31691 10.321C-0.391317 10.053 1.51502 4.18507 3.61984 3.27112C4.36611 2.94628 5.33988 2.86293 7.66748 2.96102C9.7089 3.04618 23.7761 2.63586 57.8077 1.73427C58.4746 1.90589 60.1579 1.27412 60.5573 1.48876C60.8619 1.61348 65.8047 1.5825 73.1524 1.49932C81.3971 1.40492 103.842 1.06294 114.911 0.935675C131.248 0.740343 125.103 0.763921 143.805 0.616663C147.718 0.584919 144.662 0.945798 149.262 0.806613C159.073 0.515254 180.521 0.859524 187.946 0.59256C193.901 0.377966 195.199 0.3692 200.923 0.622215C205.502 0.82351 237.809 0.94431 241.456 0.609408C242.335 0.508727 243.003 0.563086 242.983 0.691391C243.364 0.704908 264.869 1.17795 264.963 1.28463C265.115 1.41399 265.764 1.49057 266.394 1.41069C267.54 1.27257 290.917 1.29928 291.239 1.68096C292.148 2.78098 288.17 7.25701 285.359 8.31736C282.413 9.42714 267.919 7.79118 255.474 8.28966C255.474 8.28966 251.963 8.24012 246.696 8.15743C195.27 7.39403 197.024 7.71194 187.213 8.00888C185.823 7.60945 180.569 8.53751 177.31 7.85297C175.957 7.57103 166.396 7.51215 164.257 7.77817C163.799 7.83676 160.402 7.83818 155.459 7.8245C150.536 7.81093 144.067 7.77667 137.521 7.7922C134.238 7.7999 130.937 7.80191 127.807 7.82172C124.658 7.83583 121.662 7.8788 119.009 7.92388C113.684 8.01393 109.714 8.14024 108.491 8.3449C98.7815 7.73789 64.3846 9.0616 55.1079 9.39533Z" fill="#FFBA00" />
    </svg>
  </span>
)
