import React from "react"

export const TitleUnderlineStartOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="193" height="11" viewBox="0 0 193 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.7545 10.1214C31.7816 9.17961 2.66452 10.1171 0.549316 9.83012C-1.17705 9.44911 1.60632 2.85225 3.21327 1.88488C3.77656 1.53709 4.42976 1.48846 5.9254 1.6525C8.29679 1.9594 38.9747 1.4723 38.9747 1.4723C39.3791 1.67714 40.5965 0.994577 40.8189 1.24331C41.5196 1.8243 91.2435 1.20482 95.4842 1.18743C98.055 1.17709 96.0202 1.5644 99.0462 1.41572C105.495 1.11971 119.546 1.47266 124.433 1.12684C128.344 0.841288 129.187 0.831445 132.939 1.08581C136.774 1.31503 157.168 1.07276 159.5 0.616526C160.071 0.491299 160.509 0.537299 160.509 0.68354C160.509 0.715331 174.883 0.872262 174.949 0.986936C175.056 1.13354 175.494 1.20497 175.899 1.10461C176.652 0.954567 191.955 0.453387 192.193 0.880205C192.941 2.10355 190.815 7.29745 189.034 8.5567C187.211 9.90484 177.23 8.35129 169.201 9.13788L131.829 9.09355L123.817 9.57499C122.901 9.13315 119.368 10.2275 117.238 9.43182C116.338 9.11721 109.973 9.05107 108.567 9.37692C107.342 9.6271 74.784 8.87427 71.4415 9.74672C65.4923 8.98894 41.3514 9.96242 35.7545 10.1214Z" fill="#FFBA00"/>
      </svg>
      
  </span>
)

export const TitleUnderlineStartTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="243" height="11" viewBox="0 0 243 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.1642 10.1534C40.1448 9.20803 3.36517 10.1195 0.693018 9.83061C-1.48805 9.44806 2.02197 2.85366 4.05099 1.88773C4.76222 1.54044 5.58729 1.49239 7.47672 1.65777C10.4725 1.96679 49.2241 1.50713 49.2241 1.50713C49.7351 1.71233 51.2724 1.03085 51.5535 1.27978C52.439 1.8614 115.249 1.28639 120.606 1.2728C123.854 1.26476 121.283 1.65024 125.106 1.50427C133.251 1.21403 151.001 1.57955 157.174 1.23809C162.114 0.956037 163.179 0.946948 167.919 1.20467C172.763 1.43731 198.524 1.21329 201.47 0.759138C202.191 0.63442 202.744 0.680813 202.744 0.827053C202.744 0.858844 220.902 1.02863 220.985 1.14336C221.12 1.29006 221.673 1.36188 222.185 1.26189C223.136 1.11252 242.466 0.625021 242.767 1.05205C243.713 2.27607 241.033 7.46808 238.784 8.72575C236.482 10.0723 223.873 8.50978 213.731 9.28919L166.524 9.21144L156.404 9.68572C155.246 9.24306 150.784 10.3342 148.092 9.53667C146.955 9.22124 138.915 9.14941 137.139 9.47401C135.593 9.72309 94.4647 8.94115 90.2432 9.81061C82.7276 9.04751 52.2339 9.9994 45.1642 10.1534Z" fill="#FFBA00" />
    </svg>
  </span>
)
