import React from "react"

const useTicker = ({ data }) => {
  return (
    <section className="container-cards-use-page-alternative-telemarketing">
      <h2 className="title-question-pages-relative">      
      Con Beex, también llevamos a un siguiente nivel tu central telefónica
      </h2>
      <section className="cards-imagen-pages-relative">
        {data.map((ele, i) => {
          return (
            <div key={i} className="cards-useticker">
              <img
                src={ele.icon}
                alt=""
               
              />
              <p className="cards-useticker-title">{ele.title}</p>
              <p>{ele.text}</p>
            </div>
          )
        })}
      </section>
 
    </section>
  )
}

export default useTicker
